import { Locale } from '@bemlo/enums'

import { Profession } from '../../generated/graphql'
import { factories } from '../factories'
import { pluralize } from '../utils'

import type { Translation, TranslationBase, TranslationNamespace } from './en'

const IS_JOBS_FEATURE_ENABLED = false

const COMPANY_NAME = 'Bemlo'
const COMPANY_NAME_POSSESSIVE = 'Bemlos'
const SITE_NAME = 'Bemlo'
const SITE_NAME_POSSESSIVE = 'Bemlos'

const jobs = pluralize('jobb', 'jobber')
const reviews_ = pluralize('anmeldelse', 'anmeldelser')
const salaries = pluralize('lønn', 'lønninger')

const auth: TranslationNamespace<'auth'> = {
  choose_log_in_method: 'Velg innloggingsmetode',
  click_link_in_email_to_log_in: 'Trykk på linken i e-posten for å logge inn.',
  code: 'Kode',
  email_address_in_use: 'E-postadressen er allerede i bruk',
  have_not_registered_yet: 'Ikke registrert deg enda?',
  insert_code_sent_by_sms: 'Skriv inn koden som ble sendt til deg via SMS.',
  log_in: 'Logg inn',
  log_in_at_our_site: `Logg inn på ${SITE_NAME}`,
  log_in_description:
    'Se hvilke oppdrag og jobber vi har funnet til deg siden sist.',
  log_in_with_email: 'Logg inn med e-post',
  log_in_with_sms: 'Logg inn med SMS',
  no_account_connected_to_email:
    'Det er ingen konto knyttet til denne e -postadressen',
  no_account_connected_to_phonenumber:
    'Det er ingen konto knyttet til dette telefonnummeret',
  phone_number_in_use: 'Telefonnummeret er allerede i bruk',
  register: 'Registrer deg',
  specify_email_you_used: `Skriv inn e-postadressen du brukte da du meldte deg på ${SITE_NAME}.`,
  specify_phone_number_you_used: `Skriv inn telefonnummeret du brukte da du meldte deg på ${SITE_NAME}.`,
}

const base: TranslationBase = {
  about_us: 'Om Oss',
  add: 'Legg til',
  all_departments: 'Alle enheter',
  all_fields_must_be_provided: 'Alle felter må fylles ut',
  all_professions: 'Alle jobber',
  all_regions: 'Alle regioner',
  all_specializations: 'Alle videreutdanninger',
  apply_for_job: 'Søk',
  back: 'Tilbake',
  blog: 'Blogg',
  cancel: 'Avbryt',
  collective_agreement: 'Tariffavtale',
  comment_min_length: (minLength: number) =>
    `Kommentaren må inneholde minst ${minLength} tegn`,
  email: 'Email',
  email_placeholder: 'abc@xyz.no',
  gathered_best_jobs_for_you_description:
    'Vi har samlet bemanningsjobber fra flere bemanningsbyråer, slik at du enkelt kan finne den som passer deg best.',
  invalid_email: 'Emailadressen er ikke gyldig',
  loading: 'Laster...',
  log_in: 'Logg in',
  mandatory: 'Obligatorisk',
  menu: 'Meny',
  name: 'Navn',
  name_placeholer: 'Ola Nordmann',
  no: 'Nei',
  no_collective_agreement: 'Ingen tariffavtale',
  no_match_found: 'Ingen treff funnet',
  norway: 'Norge',
  obligatory: 'Obligatorisk',
  optional: 'valgfritt',
  other: 'Annen',
  phone_number: 'Telefonnummer',
  phone_placeholder: '+47 987 65 432',
  procurements: 'Anskaffelser',
  profession: 'Yrke',
  read_more: 'Les mer',
  region: 'Region',
  remove: 'Fjerne',
  salary_statistics: 'Lønnsstatistikk',
  select_profession: 'Velg yrke',
  share: 'Del',
  show_all: 'Vis alle',
  show_more: 'Vis mer',
  staffing_tips: 'Bemanningstips',
  submit: 'Send inn',
  submitting: 'Sender inn...',
  sweden: 'Sverige',
  table_of_contents: 'Oversikt',
  update: 'Oppdater',
  whats_your_profession: 'Hva er yrket ditt?',
  workers_in: (profession: Profession) =>
    base.$profession(profession, 'plural').toLowerCase(),
  yes: 'Ja',
  ...factories(Locale.NB),
}

const blog: TranslationNamespace<'blog'> = {
  blog_heading: 'Artikler for deg som jobber i helsesektoren',
  blog_paragraph_1: `På ${SITE_NAME} kan du finne artikler med relevant og nyttig informasjon for arbeidere i helsesektoren.`,
  blog_paragraph_2: 'Noe du kunne tenkt deg en artikkel om?',
  comment: 'Kommentar',
  mail_us_at: 'Send en mail til',
  no_blog_posts_yet: 'Det finnes ingen blogginnlegg enda.',
  published: 'Publisert',
  read_on: (siteName) => `Les på ${siteName}`,
  reading_time: (minutes: number) => `${minutes} min lesing`,
  seo_title: 'Les artikler om bemanning innen helsesektoren',
  updated: 'Oppdatert',
  write_a_comment: 'Skriv en kommentar',
}

const companiesList: TranslationNamespace<'companiesList'> = {
  best_for_doctors: 'Beste vikarbyråer for leger',
  best_for_nurses: 'Beste vikarbyråer for sykepleiere',
  doctors: 'leger',
  find_best_companies: 'Finn de beste vikarbyråene',
  find_best_companies_for: (profession: string) =>
    `Finn det beste vikarbyrået for ${profession}`,
  keywords: ['vurderinger', 'bemanningsbyrå'],
  nurses: 'sykepleiere',
  nurses_and_doctors: 'sykepleiere og leger',
  read_what_others_think: (professions: string) =>
    `Les hva andre ${professions} har sagt før du velger ditt neste vikarbyrå. Vi har samlet alle vikarbyråene på ett sted og gjort det enkelt å finne de beste blant dem.`,
  see_also: 'Se også:',
}

const companyFaq: TranslationNamespace<'companyFaq'> = {
  apply_now: 'Søk nå',
  does_company_have_collective_agreement: (name) => `Har ${name} tariffavtale?`,
  how_many_consultants_work_at_company: (name) =>
    `Hvor mange konsulenter jobber hos ${name}?`,
  no_company_does_not_have_collective_agreement: (name) =>
    `Nei – ${name} har ikke en tariffavtale.`,
  number_of_consultants_text: (count: number | null, name) =>
    count
      ? `Om man teller heltidskonsulenter jobber ca. ${count} konsulenter hos ${name}. ` +
        'Et eksakt tall er vanskelig å vite med sikkerhet.'
      : 'Vi har desverre ikke denne informasjonen.',
  read_more: 'Les mer',
  yes_company_has_collective_agreement: (name) =>
    `Ja – ${name} har en tariffavtale!`,
}

const companyPage: TranslationNamespace<'companyPage'> = {
  about_company: (name) => `Om ${name}`,
  authorization_description:
    'Almega sertifiserer bemanningsbyråer som har tariffavtaler og inffrir et sett av spesifikke kriterier.',
  authorized_collective_agreement: 'Autorisert av Almega',
  company_job_postings: (name: string) => `${name} – Ledige oppdrag`,
  company_page_description: (name) =>
    `Se hva andre synes om å jobbe hos ${name}. Se lønninger og hvilke regioner ` +
    `${name} opererer i. Søk på jobber hos ${name} direkte på ${SITE_NAME}.`,
  find_jobs: 'Finn jobber',
  go_back: 'Hovedside',
  go_to_company_list: 'Til firmalisten',
  in_region: (region) => `i ${region}`,
  jobs: 'Jobber',
  new: 'Ny',
  no_procurements_found_for: (name) =>
    `Vi kunne ikke finne noen offentlige stillinger for ${name}.`,
  other_top_ranked_companies: 'Andre høyt rangerte selskaper',
  read_more: 'Les mer',
  respond_to_contact_request_from: (name) =>
    `Svar på kontaktforespørsel fra ${name}`,
  reviews: 'Vurderinger',
  salaries: 'Lønn',
  salary_statistics: 'Lønnsstatistikk',
  see_other_consultant_salaries: (
    profession: Profession,
    regionCount: number,
  ) =>
    `Se lønn for andre ${base.workers_in(profession)} i mer enn ` +
    `${regionCount} regioner.`,
  see_user_reported_salaries: (count?: number) =>
    `Se${count ? ` ${count}` : ''} brukerregistrerte ${salaries(count)}`,
  see_what_others_think_of_company: (name) =>
    `Se hva andre mener om ${name} og sammenlign selskaper`,
  summary: 'Sammendrag',
  the_text_is_taken_from: (source: string) => `Teksten er hentet fra ${source}`,
  the_text_is_taken_from_companys_website: (name) =>
    `Teksten er hentet fra nettsiden til ${name}`,
  you_and_company_are_in_contact: (name) => `Du og ${name} er i kontakt`,
}

const companyTable: TranslationNamespace<'companyTable'> = {
  ad: 'Ad',
  company: 'Selskap',
  find_companies: 'Finn selskaper',
  missing_companies_info: 'Mangler vi et selskap? Si ifra på',
  name: 'Navn',
  number_of_consultants: 'Antall bemanningsansatte',
  rating: 'Vurdering',
  rating_ascending: 'Vurdering, lavest først',
  rating_descending: 'Vurdering, høyest først',
  region: 'Region',
  showing_region: 'Viser region',
  sort: 'Sorter',
  sort_by: 'Sorter etter',
}

const ctaPanel: TranslationNamespace<'ctaPanel'> = {
  compare_companies: 'Sammenlign selskaper',
  compare_staffing_companies_for: () => `Sammenlign 50+ vikarbyrå!`,
  easily_send_applications_to_companies: 'Enkelt send søknader til selskaper',
  see_information_about_salaries_and_benefits:
    'Se informasjon om lønn og fordeler',
  see_reviews_from_others_in: (profession: Profession) =>
    `Se anmeldelser fra andre ${base.workers_in(profession)}`,
}

const deleteProfileRedirect: TranslationNamespace<'deleteProfileRedirect'> = {
  we_are_sorry_to_see_you_leaving: 'Vi beklager å se deg forlate Bemlo!',
  you_are_always_welcome_back: 'Du er alltid velkommen tilbake',
}

const cookieConsent: TranslationNamespace<'cookieConsent'> = {
  accept_all: 'Godkjenn alle',
  accept_all_cookies: 'Godkjenn',
  accept_selected: 'Godkjenn valgte',
  analytics_cookies: 'Analysecookies',
  analytics_cookies_description:
    'Hjelper oss å forstå hvordan nettstedet brukes, slik at vi kan forbedre det.',
  choose_cookies: 'Velg hvilke cookies som kan lagres i nettleseren din.',
  cookies_description:
    'Vi bruker kun cookies for å forbedre opplevelsen din på nettstedet vårt. Noen cookies brukes til at nettstedet skal fungere ordentlig og andre for å forbedre opplevelsen din. Vil du godta alle cookies?',
  custom_selection: 'Velg selv',
  how_we_use_cookies: 'Les mer om hvordan vi bruker cookies.',
  necessary_cookies: 'Nødvendige cookies',
  necessary_cookies_description:
    'Nødvendige cookies brukes for at nettstedet skal fungere. Muliggjør viktige funksjoner, som pålogging.',
  we_value_your_privacy: 'Vi verner om personvernet ditt',
}

const fallback: TranslationNamespace<'fallback'> = {
  an_error_occurred: 'En feil oppstod',
  to_the_start_page: 'Til startssiden',
  were_working_on_a_fix: 'Vi jobber med en utbedring.',
}

const faq: TranslationNamespace<'faq'> = {
  get_answers_to_your_questions_about_staffing:
    'Få svar på dine spørsmål om bemanning',
  no_questions: 'Vi fant ingen spørsmål.',
  questions: 'Spørsmål',
  subtitle: (email) => (
    <>
      Finner du ikke svar på spørsmålet ditt?
      <br />
      Kontakt oss på{' '}
      <a href={`mailto:${email}`} style={{ color: 'white' }}>
        {email}
      </a>
      .
    </>
  ),
  title: `Ofte stilte spørsmål om bemanning – ${SITE_NAME}`,
}

const footer: TranslationNamespace<'footer'> = {
  career_cta: 'Utvikler? Søk nå.',
  cookie_policy: 'Informasjon om cookies',
}

const header: TranslationNamespace<'header'> = {
  find_a_job: 'Finn en jobb',
  home: 'Hjem',
  jobs: 'Jobber',
  log_in: 'Logg inn',
  log_out: 'Logg ut',
  my_profile: 'Min Profil',
  salary_calculator: 'Lønnskalkulator',
  see_my_profile: 'Min Profil',
  show_the_page_for: 'Vis siden for',
  switch_language: 'På svenska',
  vardforbundet: 'Vårdförbundet',
}

const iePopup: TranslationNamespace<'iePopup'> = {
  download_chrome_here: 'Last ned Chrome her',
  page_does_not_work_with_ie:
    `Desverre funker ${SITE_NAME} dårlig med Internet Explorer. ` +
    'Vi anbefaler derfor å bytte til en annen nettleser, for eksempel Chrome eller Firefox.',
}

const jobBoard: TranslationNamespace<'jobBoard'> = {
  apply: 'Søk',
  apply_by: 'Søk innen',
  apply_directly_on_website: () => `Søk direkte på ${meta.site_name}`,
  bemlo_logotype_alt_text: 'Bemlos logotype',
  could_not_find_any_matching_published_vacancies:
    'Kunne ikke finne noen matchende publiserte oppdrag',
  could_not_find_any_matching_vacancies:
    'Kunne ikke finne noen matchende oppdrag',
  create_a_profile: 'Opprett profil',
  create_a_profile_description:
    'Opprett en profil og få tilbud om matchende oppdrag direkte fra flere bemanningsbyråer.',
  department_other: 'Enheter',
  distance_work: 'Fjernarbeid',
  earliest_job_start: 'Tidligste oppdragsstart',
  estimated: 'Estimert',
  for_the_job: 'på jobben',
  full_time_description: 'Oppdraget er på 100%',
  hour_short: 't',
  invoice_verb: 'Fakturer',
  irregular_part_time_description:
    'Oppdraget innbærer enkeltstående vakter nå og da',
  job_board_paragraph:
    'Vi samler tilgjengelige jobber fra bemanningsbyråene. Som helsepersonell kan du enkelt finne det som passer deg best! Om du ikke finner en perfekt match for deg burde du lage deg en profil, så byråene kan kontakte deg direkte. ',
  job_mediated_by_company: (company: string) => `Jobb formidlet av ${company}`,
  job_start: 'Oppdragsstart',
  jobs_page_title: 'Ledige jobber i Norge',
  match: 'match',
  month_short: 'mnd',
  new: 'Ny',
  no_description: 'Ingen beskrivelse',
  profession_title: 'Yrkestittel',
  published_at_date: (date: string) => `Publisert ${date}`,
  region: 'Region',
  region_header_alt_text: 'Bilde av regionen oppdraget utføres i',
  regular_part_time_description: 'Oppdraget er på mellom 20-80%',
  reset_filters: 'Tilbakestill filter',
  salary: (profession: Profession) =>
    profession === Profession.DOCTOR ? 'kompensasjon' : 'lønn',
  show_all_departments: 'Vis alle enheter',
  showing_x_out_of_y_vacancies: (x: number, y: number) =>
    `Viser **${x}** av **${y}** annonser`,
  specialization_other: 'Spesialiseringer',
  staffing_job_in_region: (municipality: string) =>
    `Bemanning i ${municipality}`,
  the_job_is_located_in_municipality: (municipality: string) =>
    `Oppdraget ligger i ${municipality}`,
  unknown_date: 'Ukjent dato',
  until_further_notice: 'Inntil videre',
  vacancies_for_company: (companyName?: string) =>
    `Ledige stillinger${companyName ? ` hos ${companyName}` : ''}`,
  vacancies_for_nurses_and_doctors:
    'Ledige jobber innen bemanning for leger, sykepleier og annet helsepersonell i Norge.',
  week_number: (week: string) => `Uke ${week}`,
}

const jobPage: TranslationNamespace<'jobPage'> = {
  application_sent: 'Søknad sendt',
  apply_now: 'Søk nå',
  create_an_account: 'Create an account',
  create_an_account_description:
    'Do you want to know more about the workplace?',
  cta_section_heading:
    'Bli matchet med bemanningsjobben som er perfekt for deg',
  cta_section_paragraph: 'Sammenlign selskaper og finn drømmejobben.',
  explore_similar_jobs: 'Se lignende jobber',
  open_map: 'Åpne kart',
  see_more_jobs: 'Se flere jobber',
  see_the_jobs: 'Se jobbene',
  your_workplace: 'Din arbeidsplass',
}

const landingPage: TranslationNamespace<'landingPage'> = {
  apply_now: 'Søk nå',
  find_the_best_staffing_company: 'Finn det perfekte bemanningsbyrået',
  seo_description_profession: (profession: Profession) =>
    `Se alle vikarbyråene for ${base.workers_in(profession)} på ett sted`,
  ...(IS_JOBS_FEATURE_ENABLED
    ? {
        landing_page_heading_1: 'Bli matchet med bemanningsjobben',
        landing_page_heading_2: 'som er perfekt for deg',
        landing_page_paragraph_1: 'Er du klar for lønnen du fortjener?',
        landing_page_paragraph_2: 'Sammenlign selskaper og finn drømmejobben.',
      }
    : {
        landing_page_heading_1: 'Finn de beste vikarbyråene',
        landing_page_heading_2: 'i helsesektoren',
        landing_page_paragraph_1:
          'Les anmeldelser fra selskapenes ansatte og la byråene konkurrere om deg.',
        landing_page_paragraph_2: '',
      }),
  log_in_at_our_site: `Logg inn hos ${SITE_NAME}`,
  see_jobs: (count: number) => `Se ${count} ${jobs(count)}`,
  see_the_company_rankings: 'Se selskapsrangeringen',
  see_the_jobs: 'Se jobbene',
  so_that_you_can_easily_compare_terms_and_salaries:
    'Så du lett kan sammenligne vilkår og lønn',
  we_present_staffing_jobs_from_many_companies:
    'Vi viser bemanningsjobber fra mange selskaper',
}

export const landingPage2: TranslationNamespace<'landingPage2'> = {
  apply_now: 'Søk nå',
  are_you_a_care_giver: () => 'Er du en helseinstitusjon?',
  blog_button: 'Flere artikler',
  blog_heading: 'Livet som vikarsykepleier',
  blog_paragraph_for_profession: (profession: Profession) =>
    `På ${SITE_NAME} publiser vi artikler for ${base.workers_in(
      profession,
    )} med mål om å spre informasjon om livet som vikar innen helse.`,
  blog_paragraph_generic: `På ${SITE_NAME} publiser vi artikler med mål om å spre informasjon om livet som vikar innen helse.`,
  blog_read_more: 'Les mer',
  companies_and_many_more: '...og mange flere',
  companies_based_on_reviews: (count: number) =>
    `Basert på ${count} ${reviews_(count)}`,
  companies_heading: IS_JOBS_FEATURE_ENABLED
    ? 'Vi matcher deg med de beste vikarbyråene.'
    : 'Vi har samlet vikarbyråene.',
  companies_see_the_companies: 'Se selskapene',
  create_a_profile: 'Lag en profil',
  cta_button: IS_JOBS_FEATURE_ENABLED ? 'Finn min drømmejobb' : 'Se selskapene',
  cta_heading_1: `${
    IS_JOBS_FEATURE_ENABLED ? 'Bli matchet med' : 'Finn'
  } vikarjobbene`,
  cta_heading_2: 'som passer deg best',
  cta_paragraph: 'Sammenlign selskaper og finn det perfekte oppdraget for deg.',
  explanation_button: 'Kom i gang på 3 minutter',
  explanation_heading: `Slik fungerer ${SITE_NAME}`,
  explanation_step_1_heading: 'Lag en profil',
  explanation_step_1_paragraph: (profession: Profession) =>
    `Ved å opprette en profil på ${SITE_NAME}, kan du samle informasjonen om deg som ${base
      .$profession(profession)
      .toLowerCase()} på ett sted, og la vikarbyråene konkurrere om deg.`,
  explanation_step_1_paragraph_generic: `Ved å opprette en profil på ${SITE_NAME}, kan du samle informasjonen om deg på ett sted, og la vikarbyråene konkurrere om deg.`,
  explanation_step_2_heading: 'Få jobbtilbud',
  explanation_step_2_paragraph:
    'Vi setter vikarbyråene i kontakt med deg basert på når og hvor du vil jobbe, slik at du kan få de beste jobbtilbudene.',
  explanation_step_3_heading: 'Begynn å jobbe',
  explanation_step_3_paragraph:
    'Vi sørger for at du får så mange gode jobbtilbud som mulig, slik at du kan velge det som passer deg best uten ekstra innsats.',
  intro_button: 'Se jobbene',
  intro_clarification: 'Helt gratis – kom i gang på 3 min!',
  product_agency_management: () => 'Byråstyring',
  product_ai_scheduling: () => 'AI-planlegging',
  product_pools: () => 'Vakanshåndtering',
  see_bemlos_tools: () =>
    'Se Bemlos ledende verktøy for vaktplanlegging og vikarstyring',
  ...(IS_JOBS_FEATURE_ENABLED
    ? {
        intro_heading_1: (profession: Profession) =>
          `${base.$profession(profession)}? Vi har jobbene `,
        intro_heading_2: () => '',
        intro_heading_3: () => 'på ett sted.',
        intro_paragraph_1: 'Klar for vilkårene du fortjener?',
        intro_paragraph_2:
          'Vi matcher deg med de beste jobbene blant 50+ vikarbyrå.',
      }
    : {
        intro_heading_1: (_: Profession) => <>Finn de beste vikarbyråene </>,
        intro_heading_2: (_: Profession) => `for `,
        intro_heading_3: (profession: Profession) =>
          `${base.workers_in(profession)}.`,
        intro_paragraph_1: 'Les anmeldelser fra tidligere ansatte,',
        intro_paragraph_2: 'og la byråene konkurrere om deg.',
      }),
  intro_image_alt: (profession: Profession) =>
    `A photography of a smiling ${base.$profession(profession).toLowerCase()}`,
  jobs_button: (count: number) => `Se ${count} andre ${jobs(count)}`,
  jobs_heading_for_profession: (profession: string) =>
    `Vi har jobbene for ${profession}`,
  jobs_heading_generic: `Vi har jobbene`,
  jobs_subscribe: 'Abonner på jobbtilbud',
  learn_1_buttonText: 'Regn ut din lønn',
  learn_1_paragraph: (profession: Profession) =>
    `Anslå hvor mye du kan tjene som en ${base
      .$profession(profession)
      .toLowerCase()} i din region, avdeling og basert på din utdannelse og erfaring.`,
  learn_1_paragraph_generic: `Få et anslag på hvor mye du kan tjene i din region og avdelingstype, basert på din utdannelse og erfaring.`,
  learn_1_title: 'Lønnskalkulator',
  learn_2_buttonText: 'Sammenlign lønn',
  learn_2_paragraph:
    'Se innsendte lønnstall fra kolleger med erfaring fra vikarbyrå.',
  learn_2_title: 'Lønnsstatistikk',
  learn_3_buttonText: 'Til artiklene',
  learn_3_paragraph:
    'Finn svar på dine spørsmål om vikarbransjen for helsearbeidere. Hvordan du kan få de beste vilkårene?  Og mye mer.',
  learn_3_title: 'Vår Blogg',
  learn_4_buttonText: 'Se alle lønnene',
  learn_4_paragraph:
    'Søk opp hvor, og for hvilket selskap, du kan oppnå den høyeste lønnen. Vi viser deg alle de kommunale og regionale avtalene.',
  learn_4_title: 'Offentlige anskaffelser',
  learn_5_buttonText: 'Til spørsmålene',
  learn_5_paragraph:
    'Få svar på ofte stilte spørsmål om det å jobbe i vikarbyrå.',
  learn_5_title: 'Ofte stilte spørsmål',
  learn_heading: 'Lær om bemanningsbransjen',
}

/**
 * TODO: Add support for using multiple namespaces, and add a "form" namespace.
 */
const leads: TranslationNamespace<'leads'> = {
  companyHeading: (name) => `Interessert i å jobbe for ${name}?`,
  education: 'Hva er din utdannelse (inkludert videreutdanning)?',
  education_placeholder: 'Jeg har videreutdanning innen...',
  heading: 'Når du søker gjennom Bemlo vil du få flere tilbud å sammenligne',
  interests: 'Hva slags arbeid er du interessert i?',
  interests_placeholder: 'Jeg ønsker å jobbe med...',
  pageTitle: 'Søk på bemanningsjobber på 60 sekunder',
  paragraph:
    'Send inn skjemaet under, så tar vi kontakt med deg så fort vi finner noen matchende skift.',
  phone_number: 'Telefonnummer',
  profession: 'I hvilken yrkesgruppe søker du jobb?',
  submit: 'Send inn',
  thank_you_for_submitting:
    'Takk for din interesse! Vi tar kontakt i løpet av kort tid.',
  when_to_work: 'Når kan du starte, og hvor lenge ønsker du å jobbe?',
  when_to_work_placeholder: 'Jeg ønsker å jobbe...',
  where_to_work: 'Hvilke steder ønsker du å jobbe?',
  where_to_work_placeholder: 'Jeg ønsker å jobbe nært...',
}

const menuItems: TranslationNamespace<'menuItems'> = {
  about_us: 'Om Oss',
  blog: 'Artikler',
  companies: 'Rangering av vikarbyrå',
  jobs: 'Jobber',
  procurements: 'anskaffelser',
  register: 'Oversikt',
  salary_calculator: 'Lønnskalkulator',
  salary_statistics: 'Lønnsstatistikk',
  staffing_tips: 'FAQ',
  vardforbundet: 'Norsk Sykepleierforbund',
}

const meta: TranslationNamespace<'meta'> = {
  company_name: COMPANY_NAME,
  company_name_possessive: COMPANY_NAME_POSSESSIVE,
  site_description:
    'Raskt og enkelt sammenlign bemanningsbyråer innen helsesektoren',
  site_keywords: ['bemlo', 'helsevesenet', 'bemanning'],
  site_name: SITE_NAME,
  site_name_possessive: SITE_NAME_POSSESSIVE,
}

const notFound: TranslationNamespace<'notFound'> = {
  page_not_found: 'Siden ble ikke funnet',
  to_the_start_page: 'Til startssiden',
  you_found_a_page_that_doesnt_exist: 'Siden du har bedt om finnes ikke.',
}

const phone_number: TranslationNamespace<'phone_number'> = {
  enter_valid_number_which_country_code:
    'Vennligst skriv inn et gyldig telefonnummer med landskode (+xx)',
  invalid: 'Ugyldig telefonnummer',
  mandatory_field: 'Telefonnummer er et nødvendig felt',
  too_short: 'Telefonnummeret er for kort',
}

const sentry: TranslationNamespace<'sentry'> = {
  errorFormEntry:
    'Noen felter var ugyldige. Vennligst korriger feilene og prøv igjen.',
  errorGeneric:
    'En ukjent feil oppstod da du sendte inn skjemaet. Vennligst prøv igjen.',
  labelClose: 'Lukk',
  labelComments: 'Hva skjedde?',
  labelEmail: 'Email',
  labelName: 'Navn',
  labelSubmit: 'Send inn',
  subtitle: 'Vårt team har blitt varslet',
  subtitle2: 'Om du øsnker å hjelpe oss, fortell hva som skjedde under.',
  successMessage: 'Tilbakemeldingen har blitt innsendt. Tusen takk!',
  title: 'Det ser ut til at vi har problemer.',
}

const reviews: TranslationNamespace<'reviews'> = {
  be_the_first_reviewer_of: (name) =>
    `Bli den første anmelderen av ${name} ved å bruke skjemaet over.`,
  comments: 'Kommentarer',
  confirm: 'Bekreft',
  email: 'Email',
  email_must_be_valid:
    'Om emailen din er ugyldig, vil ikke anmeldelsen bli publisert.',
  go_back: 'Gå tilbake',
  guest: 'Gjest',
  have_you_worked_at_company: (name) => `Har du jobbet hos ${name}?`,
  have_you_worked_at_company_info: () =>
    'Vi aksepterer kun anmeldelser fra tidligere eller nåværende ansatte.',
  help_others: (name) => (
    <>
      Har du erfaring med å jobbe hos <strong>{name}</strong>? Hjelp andre ved å
      skrive en anmeldelse!
    </>
  ),
  leave_a_comment: 'Skriv en kommentar',
  license_number: 'HPR-nummer (deles aldri)',
  moderator: 'Moderator',
  name: 'Navn',
  next: 'Neste',
  no_reviews_available: 'Ingen anmeldelser er tilgjengelige.',
  only_show_reviews_from: 'Vis kun anmeldelser fra',
  please_leave_a_star_rating: 'Please leave a star rating',
  rate: (name) => `Vurder ${name}`,
  rating: 'Vurdering',
  review: 'Anmeldelse',
  review_could_not_be_submitted:
    'Anmeldelsen din kunne ikke sendes inn. Vennligst prøv igjen',
  review_fraud_warning_subtitle: 'Vi jobber aktivt for å forhindre juks.',
  review_fraud_warning_title:
    'En eller flere anmeldelser av dette selskapet har blitt fjernet grunnet spørsmål om deres legitimitet',
  review_security_information: (
    <>
      Ved å sende inn din anmeldelse bekrefter du at:
      <ul>
        <li>Du har jobbet hos selskapet du anmelder</li>
        <li>Du er en autorisert lege eller sykepleier</li>
        <li>Innholdet i anmeldelsen din er 100% sant</li>
      </ul>
      Din personlige informasjon vil <b>aldri</b> bli delt med noen utenfor{' '}
      {SITE_NAME}.
    </>
  ),
  start_bankid: 'Start BankID',
  start_bankid_paragraph:
    'Start BankID appen på din mobile enhet og scan QR-koden.',
  terms_and_conditions: 'vilkår og betingelser',
  thanks_for_sharing_your_experience: 'Takk for at du deler din erfaring!',
  trust_text:
    `${SITE_NAME} er bygget på tillit, og at brukeres anmeldelser er ærlige. ` +
    'Vi ber derfor om at du kun skriver anmeldelser av selskaper du har jobbet for — dette gagner alle!',
  we_take_measures_against_manipulation:
    'Vi jobber aktivt med å hindre manipulasjon av rangeringen.',
  when_you_submit_you_accept_our:
    'Ved å sende inn anerkjenner du at du aksepterer våre',
  write_a_review_for: (name) => `Skriv en anmeldelse av ${name}...`,
  xxx: 'XXX',
  your_information_will_not_be_shared_with: (name) =>
    `Informasjonen din vil ikke bli delt med ${name} eller noen andre.`,
}

const tellMeMore: TranslationNamespace<'tellMeMore'> = {
  email: 'Email',
  tell_me_more_completed: 'Registrert',
  tell_me_more_cta: 'Fortell meg mer!',
  tell_me_more_subtitle: `Med ${SITE_NAME} kan du lett sammenligne og få tilbud fra de best rangerte bemanningsbyråene.`,
  tell_me_more_title: 'Ønsker du bedre lønn?',
}

const registrationForm: TranslationNamespace<'registrationForm'> = {
  add_profile_information_so_that:
    'Legg igjen kontaktinformasjonen din slik at arbeidsgivere kan ta kontakt med deg om spennende jobbtilbud som passer dine preferanser',
  additional_contacts_more_label: 'Jeg vil ha kontakt med flere selskaper',
  additional_contacts_more_subtitle: (sitename) =>
    `Jeg vil at ${sitename} skal matche meg med flere gode selskaper`,
  additional_contacts_none_label: 'Ingen flere selskaper',
  additional_contacts_none_subtitle: 'Bare til det aktuelle selskapet',
  additional_contacts_title: 'Vil du komme i kontakt med flere selskaper?',
  additional_contacts_url_extension_string: 'kontakter',
  apply_to: (numberOfSelectedEmployers?: number) =>
    `Rapporter interesse ${numberOfSelectedEmployers} selskap`,
  biography_alternatives_free_text_placeholder:
    'Jeg leter etter oppgaver der... (fritekst) (valgfritt)',
  biography_title: 'Beskriv deg selv kort.',
  biography_url_extension_string: 'biografi',
  boom: 'Sånn!',
  confirm_application: 'Bekreft registreringen',
  contact_details_email_error_format:
    'Vennligst skriv inn en gyldig e-postadresse',
  contact_details_email_error_obligatory: 'E-post er et obligatorisk felt',
  contact_details_email_suggestion: (email: string) => `Mente du ${email}?`,
  contact_details_email_title: 'E-post',
  contact_details_error_message: 'Vennligst skriv inn for- og etternavn',
  contact_details_name_title: 'Fornavn og etternavn',
  contact_details_title: 'Fyll inn kontaktinformasjonen din',
  current_employment_status_subtitle: 'Hvilket utsagn passer best for deg?',
  current_employment_status_subtitle_update:
    'Hvilket alternativ passer best med din nåværende jobbsituasjon?',
  current_employment_status_title:
    'Hvordan ser din nåværende arbeidssituasjon ut?',
  current_employment_status_title_update: 'Det er en stund siden vi så deg!',
  current_employment_status_url_extension_string: 'idag',
  departments_interests_alternatives_box_title: 'Avdelinger',
  departments_interests_free_text_placeholder:
    'Jeg er interessert i å jobbe på følgende avdelinger... (fritekst)',
  departments_interests_free_text_title: 'Ingen alternativer som passer?',
  departments_interests_title: 'Hvilke avdelinger er du interessert i?',
  departments_interests_url_extension_string: 'avdelinger',
  error_action_back_to: (siteName: string) => `Tilbake til ${siteName}`,
  error_action_try_again: 'Prøv igjen',
  error_paragraph_1: (email: string) => (
    <>
      Noe gikk galt da søknaden ble sendt. Kontakt oss gjerne direkte på{' '}
      <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
        {email}
      </a>{' '}
      så hjelper vi deg der.
    </>
  ),
  error_paragraph_2: 'Teamet vårt jobber hardt for å løse problemet.',
  error_title: 'Noe gikk galt',
  existing_account_subtitle: (siteName: string) =>
    `Det ser ut til at du allerede har en konto på ${siteName}.`,
  existing_account_title: (siteName: string) =>
    `Logg inn på ${siteName}-kontoen din`,
  experience_alternatives_box_title: 'Erfaring',
  experience_free_text_placeholder: 'Jeg har jobbet... (fritekst)',
  experience_free_text_title: 'Ingen passende alternativ?',
  experience_title: 'Hvor mye erfaring har du?',
  experience_url_extension_string: 'erfaring',
  home_municipality_alternatives_box_title: 'Hjemkommune',
  home_municipality_box_placeholder: 'Kommune...',
  home_municipality_extension_string: 'hjemkommune',
  home_municipality_free_text_placeholder: 'Nærme...',
  home_municipality_free_text_title: 'Kommentar',
  home_municipality_title: 'Hvor bor du i dag?',
  how_much_work_full_time_description: 'Jeg er interessert i å jobbe heltid',
  how_much_work_irregular_part_time_description:
    'Jeg vil ta sporadiske skift nå og da',
  how_much_work_part_time_description:
    'Jeg leter etter stillinger mellom 20-80%',
  how_much_work_title: 'Hvor mye er du interessert i å jobbe?',
  how_much_work_url_extension_string: 'arbeidsmengde',
  job_types_irregular_parttime_subtitle:
    'Timer og skift hos private eller offentlige helseforetak',
  job_types_permanent_subtitle:
    'Permanent ansettelse hos private eller offentlige helseforetak.',
  job_types_staffing_subtitle:
    'Kortere eller lengre oppgaver for bemanningsselskaper.',
  job_types_title: 'Hvilke typer jobber interesserer deg?',
  job_types_url_extension_string: 'arbeid',
  journal_system_alternatives_box_placeholder: 'Journalsystem...',
  journal_system_alternatives_box_title: 'Journalsystem',
  journal_system_alternatives_free_text_placeholder:
    'Jeg har jobbet med... (fritekst)',
  journal_system_alternatives_free_text_title:
    'Mangler det et eller flere system?',
  journal_system_title: 'Hvilke journalsystemer har du jobbet med?',
  journal_system_url_extension_string: 'journalsystem',
  loading: 'Laster',
  move_back: 'Tilbake',
  move_forward: 'Gå videre',
  norwegian_license_subtitle: 'Har du norsk autorisasjon?',
  norwegian_license_title: 'Norsk autorisasjon',
  norwegian_license_url_extension_string: 'norsk-autorisasjon',
  page_description:
    'Registrer din profil og søk jobb hos de beste bemanningsbyråene for sykepleiere og leger.',
  page_title: 'Søk på bemanningsjobber på 60 sekunder',
  please_continue_improving_your_profile:
    'Fortsett å svare på spørsmål for å fylle ut profilen din.',
  please_tell_us_more_about_you: 'Fortell oss mer om deg selv',
  profession_title: 'Hva er ditt yrke?',
  profession_url_extension_string: 'profession',
  reference_add_first_reference: 'Legg til din første referanse',
  reference_add_reference: 'Legg til referanse',
  reference_add_reference_description:
    'Vi anbefaler å invitere din forrige leder og to kolleger.',
  reference_added_at: (at: string) => `Lagt til (${at})`,
  reference_button: (hasReferences: boolean) =>
    `Inviter ${hasReferences ? 'flere ' : ''}referanser`,
  reference_close: 'Lukk',
  reference_entered_at: (at: string) => `Referanse sendt inn (${at})`,
  reference_extension_string: 'referanser',
  reference_no_references: 'Du har ikke lagt til noen referanser ennå.',
  reference_save_and_close: 'Lagre og lukk',
  reference_subtitle:
    'Be om referanser via Bemlo for å spare tid for deg selv, referanser og bemanningsselskaper. Referansene vil motta en e -post med en lenke der de raskt kan komme med en anbefaling om deg.',
  reference_title: 'Angi referanse',
  reference_update_reference: 'Oppdater referanse',
  reference_your_description:
    'Referansene deles automatisk med arbeidsgiverne du blir matchet med.',
  reference_your_title: 'Dine referanser',
  send_application: 'Send registreringen',
  send_new_application_to: (name?: string) =>
    `Vil du sende inn en ny interessemelding${name ? ` til ${name}` : ''}?`,
  skip_question: 'Hopp over',
  specialization_alternatives_box_title: 'Videreutdanning',
  specialization_doctor_url_extension_string: 'spec-lege',
  specialization_free_text_placeholder: 'Videreutdanning innen ... (fritekst)',
  specialization_free_text_title: 'Finner du ikke din videreutdanning?',
  specialization_nurse_url_extension_string: 'spec-sykepleier',
  specialization_title: 'Har du noen videreutdanning?',
  success_welcome_message: 'Nå er det første trinnet fullført.',
  swedish_license_subtitle: 'Har du svensk autorisasjon?',
  swedish_license_title: 'Svensk autorisasjon',
  swedish_license_url_extension_string: 'svensk-autorisasjon',
  to_company: (name?: string) => `Til ${name ?? 'firmaet'}`,
  to_jobs: 'Til ledige stillinger',
  waiting_for_answers: 'Venter på respons',
  we_recommend_picking_1_3_companies: 'Vi anbefaler deg å velge 1-3 selskaper',
  were_handling_your_application: 'Vi behandler søknaden din så snart vi kan.',
  when_to_start_exact_placeholder: 'Velg dato',
  when_to_start_exact_title: 'Når kan du tidligst begynne?',
  when_to_start_exact_url_extension_string: 'tidligst',
  which_companies_do_you_want_to_recieve_offers_from:
    'Hvilke selskaper vil du få tilbud fra?',
  which_countries_extension_string: 'land',
  which_countries_title: 'I hvilke land er du interessert i å jobbe?',
  which_regions_affected_by_karens_alternatives_label: (alternative: string) =>
    `Region ${alternative}`,
  which_regions_affected_by_karens_box_placeholder: 'Region...',
  which_regions_affected_by_karens_box_title: 'Regioner',
  which_regions_affected_by_karens_free_text_placeholder:
    'Det er slik at ... (fritekst)',
  which_regions_affected_by_karens_free_text_title: 'Noe du vil legge til?',
  which_regions_affected_by_karens_string: 'karens',
  which_regions_affected_by_karens_title:
    'Har du jobbet for noen av disse arbeidsgiverne de siste 6 måneder?',
  which_regions_to_work_in_alternatives_box_title: 'Regioner',
  which_regions_to_work_in_free_text_placeholder:
    'Jeg kan jobbe i... (fritekst)',
  which_regions_to_work_in_free_text_title: 'Ingen passende alternativ?',
  which_regions_to_work_in_title: 'I hvilke regioner vil du jobbe?',
  which_work_form_subtitle: 'Hvordan vil du jobbe?',
  which_work_form_title: 'Ønsket form for arbeidsavtale',
  which_work_form_url_extension_string: 'arbeidsavtale',
  your_profession_does_not_match_the_vacancy_profession:
    'Ditt registrerte yrke matcher ikke med jobben du søker på.',
  youre_application_have_been_sent: 'Søknaden din sendes',
  youve_recently_sent_an_application:
    'Du har nylig sendt en erklæring av interesse.',
}

const contactRequest = {
  already_in_contact: 'Du er allerede i kontakt med denne arbeidsgiveren',
  contact_request_reject_header: (employerName: string) =>
    `Avslå kontaktforespørsel fra ${employerName}?`,
  contact_request_reject_header_subheader: 'Å svare er frivillig',
  decline: 'Avslå',
  dislike_company: 'Liker ikke selskapet',
  do_you_want_accept_the_contact_request: 'Vil du godta kontaktforespørselen?',
  free_text_extra_comment: 'Noe du vil legge til?',
  free_text_extra_comment_placeholder: 'Fordi... (fritekst)',
  reason: 'Årsaken',
  select: 'Velg...',
}

const profile: TranslationNamespace<'profile'> = {
  about_you: 'Om deg',
  accept: 'Godta',
  add_authorization: 'Legg til Autorisasjon',
  add_authorization_description: 'Legg til autorisasjonsnummeret (HPR) ditt',
  add_authorization_error: 'Autorisasjonsnummeret er ugyldig',
  add_authorization_title: 'Mellom 6 og 9 sifre',
  answer: 'Svara',
  application_is_sent: 'Søknaden sendes',
  apply_cta_subtitle: 'Finn din neste drømmejobb',
  apply_cta_title: 'Klar for nye oppdrag?',
  authorization: 'Autorisasjon',
  back: 'Tilbake',
  bio: 'Biografi',
  close: 'Lukk',
  contacts: 'Kontakter',
  countries: 'Land',
  current_employee_status: 'Nåværende stilling',
  decline: 'Avslå',
  delete_document: 'Slett dokumenter ',
  delete_file: (fileName: string) => 'Slett ' + fileName,
  delete_profile: 'Slett profilen',
  delete_profile_confirmation:
    'Er du sikker på at du vil slette profilen din? Dette kan ikke angres.',
  delete_profile_description: 'Slett profilen din. Dette kan ikke angres.',
  departments: 'Virksomheter',
  do_you_want_to_upload_this_file: 'Vil du laste opp filen?',
  document_one: 'Dokument',
  document_other: 'Dokumenter',
  download_document: 'Last ned dokumenter ',
  download_file: (fileName: string) => 'Last ned ' + fileName,
  email: 'E-post',
  employers_in_contact_with_you: 'Arbeidsgivere du er i kontakt med',
  experience: 'Erfaring',
  file_could_not_be_found: (email: string) =>
    'Filen ble ikke funnet. Prøv igjen eller kontakt oss på ' + email,
  file_is_uploaded: 'Filen er lastet opp!',
  file_was_not_accepted: 'Filen ble ikke akseptert, prøv med en annen fil',
  hidden_from_employers: 'Skjult for arbeidsgivere',
  home_municipality: 'Bosted',
  invite_reference: 'Inviter referanser',
  is_uploading_file: 'Last opp fil',
  jobs: 'Jobb',
  journal_systems: 'Journalsystem',
  matching_criteria: 'Matchingkriterier',
  matching_job_emails: 'Motta E-post',
  matching_job_emails_description: 'Motta e-post med matchende jobbmuligheter',
  my_profile: 'Min profil',
  n_years_of_experience: (years: number) => {
    if (!years) return 'Ingen erfaring'
    return years === 1 ? 'Ett års erfaring' : years + ' års erfaring'
  },
  name: 'Navn',
  new_contact_requests_from_employers:
    'Nye kontaktforespørsler fra arbeidsgivere',
  no_matching_job_emails: 'Ikke motta e-post om matchende jobbmuligheter',
  no_matching_jobs:
    'Vi anbefaler deg å sende en ny generell eller direktesøknad. Vi kunne dessverre ikke finne noen matchende publiserte jobber',
  no_matching_jobs_description:
    'Om du oppdaterer profilen din til å inneholde flere regioner og avdelinger øker du sjansene for å bli matchet med publiserte jobber.',
  norwegian_license: 'Norsk Autorisasjon',
  not_added: 'Ikke fullført',
  once_we_find_a_job:
    'Når vi finner en match, kontakter bemanningsselskapene deg for å diskutere tilbud og muligheter.',
  open_for_new_opportunities: 'Åpen for nye muligheter',
  overview: 'Oversikt',
  phone_number: 'Telefonnummer',
  profession: 'Yrke',
  reapply: 'Søk på nytt',
  reapply_explanation:
    'Vi starter matchingen på nytt og finner nye bemanningsbyråer som passer din profil.',
  reapply_question: 'Vil du søke på nytt?',
  receive_job_offers: 'Mottar anonyme jobbtilbud',
  regions: 'Regioner',
  replace_file: (fileName: string) => 'Erstatte ' + fileName,
  replace_with_new_document: 'Erstatt med nytt dokument ',
  scope: 'Omfang',
  see_all_jobs: 'Se alle jobber',
  select_files_to_upload: 'Velg filer som skal lastes opp',
  specialization: 'Spesialisering',
  staffing_agencies_contact_you: 'Bemanningsselskapene vil kontakte deg',
  start_date: 'Startdato',
  swedish_license: 'Svensk Autorisasjon',
  the_more_flexible_you_are:
    'Jo mer fleksibel du er, jo større er sjansen for at vi finner matchende oppgaver.Den enkleste måten å få en jobb er hvis du er fleksibel med pendling.',
  to_your_profile: 'Til profilen din',
  type_of_document: 'Dokumenttype ...',
  type_of_employment: 'Ansettelsestype',
  update_email: 'Oppdater e-post',
  update_name: 'Oppdater navn',
  update_phone_number: 'Oppdater telefonnummer',
  update_profile: 'Oppdater profil',
  upload: 'Last opp',
  upload_cv: 'Last opp din CV',
  upload_cv_description: 'Krydre profilen din med din CV',
  upload_document: 'Last opp dokumenter',
  upload_document_description: 'Last opp valgfritt dokument',
  upload_license: 'Last opp Autorisasjon',
  upload_license_description: 'Spar tid for deg selv og bemanningsselskapene',
  upload_new_file: 'Last opp ny fil',
  upload_specialization_document: 'Last opp spesialisering',
  upload_specialization_document_description: '',
  uploaded_by_you_on: (date: string) => 'Lastet opp av deg ' + date,
  we_have_sent_you_application: (companyName?: string) =>
    `Vi har sendt søknaden din${
      companyName !== '' ? ` til ${companyName}` : ''
    }!`,
  we_use_matching_to_find_jobs:
    'Vi bruker matchingkriteriene for å automatisk finne oppgaver som passer deg.',
  what_does_matching_mean: 'Hva betyr matchingkriterier?',
  what_happens_now: 'Hva skjer nå?',
  work_form: 'Ønsket arbeidsform',
  you_are_not_in_contact_with_anyone: 'Du er ikke i kontakt med noen enda.',
  you_have_selected: (fileName: string) => 'Du har valgt ' + fileName,
  you_pick_which_companies: 'Du velger oppdraget som passer deg best',
  your_application_has_been_sent: 'Søknaden din er sendt',
}

/**
 * Norwegian Bokmål [nb]
 */
const nb: Translation = {
  auth,
  base,
  blog,
  companiesList,
  companyFaq,
  companyPage,
  companyTable,
  contactRequest,
  cookieConsent,
  ctaPanel,
  deleteProfileRedirect,
  fallback,
  faq,
  footer,
  header,
  iePopup,
  jobBoard,
  jobPage,
  landingPage,
  landingPage2,
  leads,
  menuItems,
  meta,
  notFound,
  phone_number,
  profile,
  registrationForm,
  reviews,
  sentry,
  tellMeMore,
}

export const locale = Locale.NB

export default nb
